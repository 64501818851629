/* Import all fonts */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* Star Wars English Font */
@font-face {
  font-family: "Aurebesh-English";
  src: local("Aurebesh-English"),
    url("./fonts/Aurebesh-English.ttf") format("truetype");
}

/* Star Was Font */
@font-face {
  font-family: "Aurebesh";
  src: local("Aurebesh"), url("./fonts/Aurebesh.ttf") format("truetype");
}

/* Define all variables */
:root {
  --red: #c00000;
  --black: #161616;
  --white: #ffffff;
  --yellow: #ffbe0b;
  --green: #09bc8a;
  --blue: #067bc2;
  --purple: #9D75CB;


  --event-card-color-career: #c00000;
  --event-card-color-coding: #ffbe0b;
  --event-card-color-fun: #09bc8a;
  --event-card-color-talks: #9D75CB;

  --moving_left_spacing: 0px;
  --moving_width: 66px;
  --cubic: cubic-bezier(.17,.67,.83,.67);
  --body_position: unset;
}

body {
  width: 100vw;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: var(--body_position);

}

p {
  margin: 0px;
  padding: 0px;
}

.standard-padding{
  padding-left: 24px;
  padding-right: 24px;
}

.standard-margin{
  margin-left: 24px;
  margin-right: 24px;
}

/* THE FOLLOWING IS JUST SOME EXAMPLES ON HOW TO USE FONTS */
/* feel free to change, delete it all */
.Aurebesh {
  font-family: "Aurebesh";
  margin: 0;
  padding: 0;
  color: var(--red);
}

.Aurebesh-English {
  font-family: "Aurebesh-English";
  margin: 0;
  padding: 0;
  color: var(--red);
}

.Montserrat {
  font-family: "Monstserrat", sans-serif;
  margin: 0;
  padding: 0;
  color: var(--red);
}

.Inter {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  color: var(--red);
}
/* the end of examples */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

