/*
    Buttons.css is the core style sheet for all buttons across the site.
    Button classes included here are.
    Default:       The default look of buttons on the site
    default-white: A white variant of the otherwise default button
    header:        A button inteded specifically for the site header
    FL-black:      (Full Length Black) A variant of the default button which has a width of 100%
    FL-white:      (Full Length White) A variant of the FL-black button which is white
    searchbox:     A basic search container

    Update Aug 15, 2022 (Aleksandr): 
      - change fit-content to max-content for button
      - add "active" button for header movement

*/

button {
  box-sizing: border-box;
  padding: 8px 24px;
  width: max-content;
  height: 40px;
  border: 1px solid var(--black);
  border-radius: 24px;
  color: var(--black);
  background-color: transparent;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  transition: all 200ms ease-in-out;
}

button.header {
  mix-blend-mode: difference;
  color: var(--white);
  background-color: transparent;
  border: 1px solid transparent;
  transition: all 200ms ease-in-out;
}

button:hover {
  mix-blend-mode: normal;
  color: var(--white);
  background-color: var(--black);
  border: 1px solid var(--black);
}
button.selected {
  color: var(--white);
  background-color: var(--black);
  border: 1px solid var(--black);
}

button.active {
  mix-blend-mode: difference;
  color: var(--white);
  border: 1px solid transparent;
  transition-delay: 0ms;
}

button.mobile-active {
  mix-blend-mode: normal;
  color: var(--white);
  background-color: var(--black);
  border: 1px solid var(--black);
  transition-delay: 0ms;
}

button.active:hover {
  background-color: transparent;
}

button.default-white {
  color: var(--white);
  background-color: transparent;
  border: 1px solid var(--white);
}

button.default-white:hover {
    color: var(--black);
    background-color: var(--white);
    border: 1px solid var(--white);
}

button.FL-black {
  width: 100%;
}

button.FL-black:hover {
  color: var(--white);
  background-color: var(--black);
  border: 1px solid var(--black);
}

button.FL-white {
  color: var(--white);
  border: 1px solid var(--white);
  width: 100%;
}

button.FL-white:hover {
    color: var(--black);
    background-color: var(--white);
    border: 1px solid var(--white);
}

button.footer {
  color: var(--white);
  font-weight: 700;
  background-color: transparent;
  border: none;
  padding-right: 0px;
}

button.footer:hover {
  color: var(--red);
}

input.searchbox {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 16px;
  width: 232px;
  height: 40px;
  background-color: var(--white);
  border: 1px solid #000000;
  border-radius: 24px;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

input.searchbox::placeholder {
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.pagination {
  justify-content: center;
  align-items: center;
  border: none;
}

button.selectedPagination {
  border: 1px solid ;
}

button.selectedPagination:hover {
  color: black;
  background-color: transparent;
}

button.pagination:hover {
  color: black;
  border: none;
  background-color: transparent;
}
