/**
  * OurMembers.css is the style sheet associated with OurMembers.tsx, which is the 
  * Component that contains a page with brief informational cards of all the
  * CSS members who volunteer to be listed on the page. This is intended to serve as a basic means
  * of finding who you can talk to regarding the club and is a way of showing off our involvment.
  * -> OurMembers - Primary div containing entire 'Our Members' section
  * -> OurMembers-CategoryButtons - div for card filtering buttons
  * -> OurMembers-CardsContainer - div that wraps around array of cards
  * -> OurMembers-Cards - Team cards
 **/
.OurMembers {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: var(--white);
    padding-top: 80px;
    padding-bottom: 80px;
    gap: 40px;
}

.OurMembers-CategoryButtons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
}

.OurMembers-CardsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;

}    

.OurMembers-Cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
    max-width: 100%;
}