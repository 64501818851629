/**
    This css file is used for creating cards (i.e. Team Member cards or Event Cards)
    It is imported by the TeamCard.tsx & EventCard.tsx files
    There are both specific and general classes here including:
        - Card ------------------------> Parent class. Defines deneral shape of the full card
        - Expried ---------------------> Turn opacity down of past events
        - CardBorder ------------------> Adds border to card
        - SpreadColumn ----------------> Displays items in column with space between
        - SpreadRow -------------------> Displays items in row with space between
        - stack-top -------------------> stacks item at z-index 9
        - Event-img -------------------> Dimensions for event card images
        - Team-img --------------------> Dimensions and positioning for team card images
        - Card-TYPE -------------------> Event card
        - Card-H3 ---------------------> Event card
        - Card-P ----------------------> Event card
        - TeamCard-HoverDisplay -------> Team card display when hovered
        - TeamCard-DetailsHeader ------> Team card Header container
        - TeamCard-DetailsContentUpper > Team card Upper content Container (AskMe & Social Banner)
        - TeamCard-AskMe  -------------> Team card AskMe Section
        - TeamCard-Social -------------> Team card Social Banner
        - TeamCard-DetailsContentLower > Team card Lower content Container (Desc & Team)
        - TeamCard-DescBox ------------> Team card Description box
        - TeamCard-Desc ---------------> Team card Description
        - TeamCard-Team ---------------> Team Card Main Team text
*/
.Card {
    width: 462.4px;
    height: 652.8px;
    background-color: var(--black);
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    gap: 24px;
    transition: all 0.3s ease-in-out;
    border: #161616 1px solid;
}


    .Card:hover {
        transform: scale(1.03);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

.Expired {
    opacity: 65%;
}

.CardBorder {
    padding: 0px;
    border: solid 4px var(--black);
}

.SpreadColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.SpreadRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.stack-top {
    z-index: 9;
}

.ease-anim {
    transition: all 0.3s ease-in-out;
}

.Event-img {
    width: 100%;
    height: 46%;
    object-fit: cover;
}

.Team-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}

.Card-TYPE {
    width: 100%;
    height: 24px;
}

.Card-H3{
    margin: 0px;
    color: var(--white);
    font-family: Montserrat;
    /**font-size: 32px; Disable because it conflicts with transition*/
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    
}

.Card-P {
    color: var(--white);
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    overflow: scroll;
    overflow-x: hidden;
    scrollbar-color: rebeccapurple green;
    scrollbar-width: thin;
    height: 117px;
}

.TeamCard-HoverDisplay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(22, 22, 22, 0.5);
    width: 100%;
    height: 100%;
    border-radius: 21px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--white);
    transition: all 0.3s ease-in-out;
    text-shadow: -2px 0 var(--black), 0 2px var(--black), 2px 0 var(--black), 0 -2px var(--black);
}


.TeamCard-DetailsHeader {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
}

.TeamCard-DetailsContentUpper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.TeamCard-AskMe {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    list-style-type: circle;
}

    .TeamCard-AskMe p {
        margin-bottom: 0px;
    }

    .TeamCard-AskMe ul {
        margin-top: 0px;
    }

.TeamCard-Social {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 24px;
}

.TeamCard-Social a  {
    height: 40px;
}


.TeamCard-Social a img  {
    height: 40px;
}

.TeamCard-DetailsContent{
    width: calc(100% - 40px);
    padding-top: 20px;
    padding-bottom: 20px;
}

.TeamCard-DetailsContentLower {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 20px;
    width: 100%;
}

.TeamCard-DescBox {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 8px;
    margin-left: auto;
    margin-right: auto;
    gap: 8px;
    width: 100%;
    height: fit-content;
    background: #FFFFFF;
    border: 2px solid #161616;
    border-radius: 16px;
}

.TeamCard-Desc {
    font-family: 'Inter';
    font-size: 15px;
    line-height: 17px;
    color: var(--black);
    text-shadow: none;
}

.TeamCard-Team {
    font-family: 'Aurebesh-English';
}


.project-card {
    width: 600px;
    height: 456px;
    border-radius: 25px;
    position: relative;
    background: var(--black);
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

    .project-card:hover {
        transform: scale(1.03);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

.projectCard-hoverDisplay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(22, 22, 22, 0.5);
    width: 100%;
    height: 100%;
    border-radius: 21px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--white);
    text-shadow: -2px 0 var(--black), 0 2px var(--black), 2px 0 var(--black), 0 -2px var(--black);
}

.project-aurebesh {
    font-family: Aurebesh;
}

.project-desc {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin-left: 24px;
    margin-right: 24px;
    background-color: var(--white);
    border-radius: 16px;
    color: var(--black);
    text-align: left;
    text-shadow: none;
    height: 264px;
}

.project-title {
    padding-top: 16px;
    display: flex;
    align-items: flex-start;
    width: 90%;
    font-family: Montserrat;
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
}

.right-align {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.project-btns {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 8px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.project-category {
    padding-bottom: 16px;
    font-family: Aurebesh-English;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #747474;
    border-radius: 10px;
}

@media screen and (max-width: 2600px) {
    .atcb-list-item {
        font-size: xxx-large;
        width: 150%;
        margin-left: -25%;
    }
}

@media screen and (max-width: 1424px) {
    .atcb-list-item {
        font-size: xx-large;
    }
}

@media screen and (max-width: 768px) {
    .atcb-list-item {
        font-size: large;
        width: 100%;
        margin-left: 0;
    }
}

@media screen and (max-width: 568px) {

    .Card {
        width: 369px;
        height: 522.2px;
        background-color: var(--black);
        border-radius: 25px;
        position: relative;
        overflow: hidden;
        gap: 20px;
        transition: all 0.3s ease-in-out;
    }

    .Card-H3{
        margin: 0px;
        color: var(--white);
        font-family: Montserrat;
        /**font-size: 32px; Disable because it conflicts with transition*/
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        
    }

    .Card-P {
        color: var(--white);
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        overflow: scroll;
        overflow-x: hidden;
        scrollbar-color: rebeccapurple green;
        scrollbar-width: thin;
    }


    .TeamCard-AskMe {   
        font-size: 16px;
        line-height: 28px;
    }

    .TeamCard-Social {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-bottom: 12px;
    }

    .TeamCard-Social a  {
        height: 32px;
    }
    
    
    .TeamCard-Social a img  {
        height: 32px;
    }

    .TeamCard-Desc {
        font-size: 12px;
        line-height: 12px;
    }

    .project-card {
        width: 360px;
        height: 273px;
        border-radius: 25px;
        position: relative;
        background: var(--black);
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    .project-desc {
        width: fit-content;
        transform: scale(0.8);
    }

    
    .project-title {
        font-size: 24px;
        line-height: 24px;
    }
    
}