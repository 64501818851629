/**
 * WhatWeOffer.css is the style sheet for WhatWeOffer.tsx, which is the component that generates the
 * 'What We Offer' Section on the main page
    -> .WhatWeOffer - Outer 'Background' Component
    -> .WhatWeOffer-Container - Inner 'Content' Component
    -> .WhatWeOffer-Row - Interactive "offer" items container
    -> .WhatWeOffer-Row > * - Interactive "offer" items
    -> .tooltip - "Offer" item tooltip containers
    -> .tooltip .tooltiptext - "Offer" item tooltip body
    -> .tooltip .tooltiptext::after - "Offer" item tooltip body (hidden)
    -> .tooltip:hover .tooltiptext - "Offer" item tooltip body (active w/ cursor over)
 **/

.WhatWeOffer {
    /* width: 1920px; */
    max-width: 1400px; /* changed by Aleksandr on Aug 18 */
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
    background-color: var(--white)
}

.WhatWeOffer-Container {
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 80px;
}

.WhatWeOffer-Row {
    width: 1400px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* allow warpping onto multiple lines */
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 40px;
    row-gap: 80px !important;

}

.WhatWeOffer-Row > * {
    flex-basis: 31%; /* 3 items per row */
    text-decoration: none;
}

/** Variations on screen sizes for classes above */
@media screen and (max-width: 1400px) {
    .WhatWeOffer-Row {
        width: fit-content;
    }
}

@media screen and (max-width: 768px) {
    
}

.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 70%;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5%;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -40%;

  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  /** variation on tooltip for different screen sizes */
  @media screen and (max-width: 500px) {

    .tooltip .tooltiptext {
        width: 120px;
        margin-left: -60px;
        padding: 10px;
      }  
}