/**
 *  Events.css is the style sheet for Events.tsx, which is the component which displays the event page.
 *  This page allows users to find out information on events hosted by, or affiliated with, the CSS club.   
 *  -> EventsContainer - Primary div for Events Pages
 *  -> EventsContainer-Current - Card Display  
 *  -> EventsContainer-Categories - Buttons to filter events cards  
 *  -> EventsContainerTitle - (Not in use) Aligns Title
 *  -> EventsContainer-Categories > * - Changes Text size for buttons
 *  -> @media screen and (max-width: 768px) - Modifies Events page styling below screen width 768px
 **/
.EventsContainer{
    padding-top: 40px;
    padding-bottom: 80px;
}

.EventsContainer-Current{
    max-width: 1900px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    row-gap: 80px;
}

.EventsContainer-Categories{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.EventsContainerTitle{
    padding-left: 0px;
    padding-right: 0px;
    width: fit-content;
}

.EventsContainer-Categories > * {
    text-transform: capitalize;
}

@media screen and (max-width: 768px) {

    .EventsContainer-Categories{
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .EventsContainer{
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .EventsContainer-Title2{
        padding-left: 24px;
        padding-right: 24px;
        width: fit-content;
    }
}
    