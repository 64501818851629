/**
  * StartMain.css is the style sheet associated with StartMain.tsx, which is the file
  * to create the first part of CSS website.
  * -> StartMain-Container - For component alignment and style
  * -> StartMain-Content - For content alignment and style
  **/

.StartMain-Container {
    background-color: var(--white); 
    width: 100vw;
    display: flex; 
    justify-content: center;
    height: fit-content;
    width: 100%;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    align-self: stretch;
    padding-top: 80px;
    padding-bottom: 80px;
}

.StartMain-Content {
    height: fit-content;
    max-width: 1400px;
    flex-direction: row;
    justify-content: space-between;
    gap: 60px;
    padding-left: 24px;
    padding-right: 24px;
}

@media screen and (max-width: 768px) {
    .StartMain-Content {
        margin-top: -60px;
        padding-bottom: 0px;
    }
}

