/**
  * Footer.css is the style sheet for Footer.tsx:
  * -> Footer - Big outside footer container / Color / Padidng
  * -> Footer-Container - footer container (flex container to center)
  * -> Footer-First - The first part before the line 
  * -> Footer-First-Left - Left side (logo + CSS)
  * -> Footer-First-Right - Right side (buttons)
  * -> Footer-Second - After the line (logo + social media)
  * -> Footer-SocialMediaLogos - Right side footer social media logos
  **/

.Footer{
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: var(--black);
}

.Footer-Container{
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.Footer-First {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Footer-First-Left > * > img {
    width: 200px;
    height: 200px;
}

.Footer-First-Left > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
}

.Footer-First-Left > div > p {
    color: var(--white);
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    max-width: 700px;
}

.Footer-First-Left{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.Footer-First-Right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 11px;
}

.Footer-Second{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Footer-Second-SocialMedia{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

#k_logo {
    height: 40px;
    width: auto;
}

.Footer-SocialMediaLogos{
    height: 40px;
    width: auto;
}

@media screen and (max-width: 1600px) {
    .Footer-Container{
        max-width: 1200px;
    }
}

@media screen and (max-width: 1400px) {
    .Footer-Container{
        max-width: 1200px;
        padding-left: 24px;
        padding-right: 24px;
    }

}

@media screen and (max-width: 1150px) {

    .Footer-Container{
        max-width: 100%;
        padding-left: 24px;
        padding-right: 24px;
    }

    .Footer-First-Right{
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .Footer-First-Left > div > p {
        font-size: 20px;
    }
    .Footer-First-Left > * > img {
        width: 90%;
        height: auto;
    }    

    .Footer-First-Left{
        flex-direction: column;
    }

}