/**
  * MeetTeam.css is the style sheet associated with MeetTeam.tsx, which is the component that generates
  * the "Who We Are" section of the Team page.
  * -> MeetTeam-Container - primary div for original MeetTeam component (for foundation alignment)
  * -> MeetTeam-Content - secondary div that still encapsulates all content for original MeetTeam component
  * -> Title-Box - div that houses the title
  * -> bigImage - div that houses the image
  * -> Frame - div that houses the 'Who We Are' text description
  * -> buttons - div that houses this section's buttons
 **/

.MeetTeam-Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--black);
}

.MeetTeam-Content {
    justify-content: space-between;
    margin: 80px 219px;
    display: flex;
    flex-direction: column;
    row-gap: 80px;
}

.Title-Box {
}

.bigImage {

}

.Frame {
    width: 880px;   
    margin: auto;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
    color: var(--white)
}

.buttons {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: 24px;
}

