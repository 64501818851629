/**
    This css file is used for creating Offer Item in WhatWeOffer section
    It is imported by the OfferItem.tsx file
        - OfferItemText ---------------> The text displayed bellow an item
        - OfferItemImage --------------> Image above the text
*/
.OfferItemText {
    font-family: Montserrat;
    color: var(--black);
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 15px;
}
.OfferItemImage{
    width: fit-content;
}


@media screen and (max-width: 768px) {

    .OfferItemText {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
    }

    .OfferItemImage{
        width: 75px;
        height: fit-content;
    }    
}