/*
    textContainer.css is the basic stylesheet for the textContainer class.
    class naming convention is a combination of direction and color which is determined
    by textContainer Parameters. For example, the class rightblack has it's content
    aligned to the right and the text color (not including buttons, which are individually
    customizable) is black.

    the btnsleft & btnsright classes are for aligning the buttons with the buttons div.

    the textContainer class is the outermost class which aligns the image and other content
    on screen.

    Update Aug 18, 2022 (Aleksandr):
      - add max-width to textContainer
      - add font properties to textContainer
      - add 3 by 4 image (Image3by4)
      - add full-width image (ImageFullWidth)
      - add gap for buttonss
*/

.rightblack {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: right;
    color: var(--black);
}

.rightwhite {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: right;
    color: var(--white);
}

.leftblack {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: left;
    color: var(--black);
}

.leftwhite {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: left;
    color: var(--white);
}

.btnsleft {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: 24px;
}

.btnsright {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: flex-end;
    gap: 24px;
}

.textContainer {
    /* max-width: 1400px; */
    /* width: 100%; */
    /* margin: 0 10%; */
    padding: 0 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3%;
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: right;  
}

.imgFirst {
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.textContainer.fullsize{
    max-width: 100%;
}

.Image3by4{
    width: 60%;
    /* height: 516px; */
    object-fit: cover;
    border-radius: 25px;
}

.ImageFullWidth{
    overflow: visible;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.imageScaler {
    width: 4500px;
    height: 100%;
    margin-left: 128px;
}

.WhatWeBuild-Container {
    background-color: var(--black);
    width: 100%;
    display: flex;
    justify-content: right;
    overflow: hidden;
}


.MainLogo {
    width: 445px;
    height: 100%;
}

@media screen and (max-width: 1600px) {
    .Image3by4 {
        width: 688px;
        height: 516px;
    }
    .textContainer {
        padding: 0 10%;
    }
}

@media screen and (max-width: 1424px){

    .textContainer {
        width: fit-content;                        
        flex-direction: column;
        gap: 32px;
    }

    .Image3by4 {
        width: 688px;
        height: 516px;
    }

    .imageScaler {
        width: 688px;
        height: 400px;
        margin-left: 0px;
    }

    .MainLogo{
        width: 70%;
    }

    .WhatWeBuildContentChange {
        margin: 80px;
    }

    .WhatWeBuild-Container {
        justify-content: center;
    }
}

@media screen and (max-width: 768px) {

    .textContainer {
        width: fit-content;
        font-size: 20px;
        padding: 0;
    }

    .Image3by4 {
        width: 344px;
        height: 258px;
    }

    .imageScaler {
        width: 344px;
        height: 200px;
    }

    .MainLogo {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media screen and (max-width: 400px) {

    .textContainer {
        width: fit-content;
        font-size: 20px;
        padding: 0;
    }

    .Image3by4 {
        width: 344px;
        height: 258px;
        display: none;
    }

    .imageScaler {
        width: 344px;
        height: 258px;
        display: none;
    }
}