/**
    This css file is used for creating pagination 
    It is imported by the Pagination.tsx file
    There are both specific and general classes here including:
        - pagination-align ------------> Parent class. Center all pages in pagination
*/

.pagination-align {
display: flex;
flex-direction: row;
justify-content: center;
transform: scale(0.8);
padding-top: 40px;

}

@media screen and (max-width: 1080px) {
    .pagination-align{
        margin-top: auto;
        margin-bottom: auto;
    }
}
    