.Header {
    margin-bottom: 80px;
}

.Header-Container  {
    margin-left: auto;
    margin-right: auto;
    height: 78px;
    max-width: 1400px;
    top: 0px;
    border-radius: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    align-self: flex-start;
    padding: 0px;
    background-color: var(--white);
    padding-top: 8px;
    /* left: -6413px;
    top: 5814px; */
    z-index: 120;
    
    
}

.Header-Menu {
    height: 40px;
    /* max-width: 1128px; */
    /* left: 272px; top: 38px;
    // ! don't use this, use margins instead  */
    position: relative;
    border-radius: 0px;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.SVGEffects{
    position: absolute;
    z-index: -10;
}

.Header-Menu > searchbox {
    /* margin-left: 40px; 
    always use gaps instead
    */
}

.Header-MovingBlob{
    position: absolute;
    left: -4px;
    transform: translate(calc(var(--moving_left_spacing) + 5px));
    width: calc(var(--moving_width) );
    box-sizing: border-box;
    padding: 8px 24px;
    height: 40px;
    border: 1px solid var(--black);
    border-radius: 24px;
    color: var(--white);
    background-color: var(--black);
    cursor: pointer;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    transition: all 500ms ease-out;
}


#Logo {
    height: 78px;
    /* ! width: 154.69805908203125px; */
    width: auto;
    /* left: 0px;
    top: 0px; */
    border-radius: 0px;
}


.Header-Menu-Mobile{
    width: 100vw;
    height: 100vh;
    position: absolute !important;
    top: 0;
    left: 0;
    background-color: white;
    
}

.Header-Menu-Mobile-MainContainer{
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}

.Header-Menu-Mobile-Main-Container-Top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 24px;
    padding-left: 24px;
    width: calc(100vw - 48px);
    padding-top: 12px;
}

.Header-Mobile-LinesImageMobile  {
    height: 30px;
    width: 30px;
    margin-top: auto;

}

.Header-Mobile-LinesImageMobile > img {
    height: 30px;
    width: fit-content;
    margin-top: auto;
    margin-left: auto;
}

#LogoMobile{
    height: 40px;
    width: auto;
}

.Header-Menu-Mobile-MainContainer-Buttons{
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: auto;
    gap: 16px;
}

.Header-Mobile-LinesImage{
    display: none;
    width: 0px;

}


@media screen and (max-width: 1500px) {
    #Logo{
        padding-left: 24px;
    }
}

@media screen and (max-width: 1150px) {
    .Header-Menu{
        display: none;
        width: 0;
    }

    .Header-Mobile-LinesImage{
        height: 30px;
        display: inline;
        width: fit-content;
        height: 30px;
        padding-right: 24px;
        padding-bottom: 2px;
        cursor: pointer;
    }

    .Header-Mobile-LinesImage > img {
        height: 30px;
    }

    .Header {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 768px) {
    .Header-Container{
        height: 30px;
        margin-top: 10px;
        margin-bottom: 10px;
    
    }

    #Logo{
        display: none;
    }
    .Header {
        margin-bottom: 0px;
    }
}