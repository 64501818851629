/**
 *  OurProjects.tsx is the style sheet that goes with OurProjects.tsx, which is the 
 *  main component which displays the Projects page. This page give users info about
 *  kind of work the club does with links to some of our work.
 *  -> OurProjects - Primary div for the Projects Page
 *  -> OurProjects-CategoryButtons - Sorting buttons for the projects page to organize the projects list
 *  -> OurProjects-CardsContainer - Actual project cards display
 *  -> OurProjects-Cards - Used by PaginationApplicator to contain each page
 **/
.OurProjects {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: var(--white);
    padding-top: 80px;
    padding-bottom: 80px;
    gap: 40px;
}

.OurProjects-CategoryButtons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
}

.OurProjects-CardsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

.OurProjects-Cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
    max-width: 100%;
}
