.Opportunities_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    padding-top: 80px;
    padding-bottom: 80px;
    width: 100%;
}

.Opportunities_content {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.BlockContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.Content_bar {
    border-top: 8px solid var(--black);
    width: 100%;
}

.carousel {
    width: 100%;
}

.carousel-scroll-btns {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    gap: 16px;
}

.carousel-content {
    white-space: nowrap;
    transition: transform 0.3s;
}

.carousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    height: 100%;
}

.carousel-item-content {
    font-size: calc(10px + 2vmin);
    white-space: pre-wrap;
    text-align: left;
    padding-right: 128px;
}

.carousel-title {
}

.taskLink {
    color: var(--black);
}

.Resources {
    width: 80%;
}

.Resource-cards-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
}

.resource-card {
    border: solid 4px var(--black);
    text-decoration: none;
    color: var(--black);
    width: 216px;
    height: 192px;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}
    .resource-card:hover {
        transform: scale(1.03);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    .resource-card img {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 1px;
        width: 100%;
        height: 65%;
    }