/**
 *  WhatWeBuild.css is the the sheet associated with WhatWeBuild.tsx, which is the main component for the
 *  "What We Build" section of the main page.
 *  -> WhatWeBuild-Content - Positions inner component div content
 **/

.WhatWeBuild-Content  {
    margin-top: 80px;
    margin-bottom: 80px;
    margin-left: 24px;
    margin-right: 24px;
    width: fit-content;
}

/** variations for differing screen sizes */
@media screen and (min-width: 1424px) {
    .WhatWeBuild-Content  {
        margin: 0;
        width: fit-content;
    }
    .WhatWeBuild-Content > .textContainer {
        padding-right: 0;
    }
}